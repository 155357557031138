import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { down } from 'styled-breakpoints';
import styled from "styled-components";
import { Bold, H3 } from "../../components/elements";
import ClientSlide from "../../components/elements/ClientSlide";
import Container from "../../components/elements/Container";
import Image from "../../components/elements/Image";
import { theme } from "../../theme";
import { api } from "../../utils/api";
import { toast } from 'react-toastify';
import { TestimonialData } from "../../types/testimonials";
const ClientSectionWrap = styled.section`
  text-align: center;
  padding: ${props => props.theme.spacing[4]}rem 0 0;
  background: ${(props: any) => (props.theme.colors.lightOrange)} ;
`;
const ClientSlideButton = styled.section`
display: flex;
align-items: center;
justify-content: center;
margin-top: 60px;
color: ${(props: any) => (props.theme.colors.mediumGray)};
font-weight: 300;
font-size: .9rem;
padding-bottom: 100px;
${down('md')} {
  margin-top: 30px;
}
    button {
      border: none;
      margin: 10px;
      display: flex;
      align-items: center;
      background:transparent;
    }
    img{
      margin: 0 10px;
    }
`;
const ClientsSection = () => {
  const sliderRef = useRef<any>(null);
  const [testimonials, setTestimonails] = useState<TestimonialData>();
  const sliderSettings = {
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    responsive: [
      {
        breakpoint: theme.breakPoints.lg,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,

        }
      },
      {
        breakpoint: theme.breakPoints.md,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: theme.breakPoints.sm,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const notify = (message: string) => toast.error(message, {
    position: toast.POSITION.BOTTOM_LEFT
  });


  async function fetchData() {
    const msg = `An error while retrieving testimonial data!`;
    try {
      const params = {
        "populate": "media",
      };
      const { status, data } = await api("/api/testimonials", "get", params);
      if (status >= 200 && status <= 299) {
        setTestimonails(data);
      } else {
        notify(msg);
      }
    } catch (err) {
      console.log(err);
      notify(msg);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ClientSectionWrap >
      <Container>
        <H3 textColor={theme.colors.black} textAlignMob="center" textAlign="center"
          margin="12px 0 30px 0"
        >What <Bold textColor={theme.colors.black}>Clients</Bold> Are Saying</H3>
        {testimonials && testimonials?.data?.length ?
          <>
            <Slider ref={sliderRef} {...sliderSettings}>
              {testimonials?.data.map((testimonial: any, index: number) => {
                return <ClientSlide
                  key={index}
                  image={testimonial?.attributes?.media?.data?.attributes?.url}
                  alternateText={testimonial?.attributes?.media?.data?.attributes?.alternativeText}
                  address={testimonial?.attributes?.location}
                  title={testimonial?.attributes?.name}
                  body={testimonial?.attributes?.desc}
                  apiImageUrl={true}
                ></ClientSlide>;

              })}
            </Slider>
            <ClientSlideButton className='controls'>
              <button onClick={() => sliderRef.current?.slickPrev()} style={{ cursor: 'pointer' }}>
                <Image
                  src={"a-left.svg"}
                  alt="Service Image"
                /> Prev
              </button>
              <button onClick={() => sliderRef?.current?.slickNext()} style={{ cursor: 'pointer' }}>
                Next
                <Image
                  src={"a-right.svg"}
                  alt="Service Image"
                />
              </button>
            </ClientSlideButton>
          </>
          : ''}
      </Container>
    </ClientSectionWrap>


  );
};

export default ClientsSection;
