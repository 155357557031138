import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import { Bold, H5, Title } from "../elements";
import { Box } from "rebass";
import Image from "./Image";
import { theme } from "../../theme";
import { down } from 'styled-breakpoints';
import BgVectorC from '../../images/vector-c.png';
import UserIcon from '../../images/user-icon.webp';
import { truncate } from '../../utils/common';
interface ClientProps {
  image?: string;
  title?: string;
  body?: string;
  address?: string;
  alternateText?: string;
  apiImageUrl?: boolean;
}

const ImageWrap = styled.div`
  margin: auto 0;
  flex: 0 1 82px;
  border-radius: 50%;
  overflow: hidden;
  max-height: 82px;
  @media (max-width: 768px) and (orientation: landscape) {
    flex: 0 1 32px;
    max-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ClientCard = styled.article`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  justify-content: flex-start;
  background: ${(props: any) => (props.theme.colors.white)};
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.06);
  max-width: 90%;
  padding: 40px;
  padding-bottom: 70px;
  min-height: 350px;
  margin: auto;
  position: relative;
  ::after {
    position: absolute;
  content: "";
  height: 100px;
  width: 100px;
  right: 15px;
  top: 15px;
  z-index: 0;
  background-image: url(${BgVectorC});
  background-repeat: no-repeat;
  ${down('lg')} { 
    display: none;
  }
  }
  ${down('md')} { 
    max-width: 100%;
  }
`;
const ClientDetail = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  min-height: 82px;
  justify-content: flex-start;
  align-items: center;
  
`;
const ClientContent = styled.div`
  text-align: left;
  min-height: 155px;
  `;

const ProfileIcon = styled.img`
  width: 100%;
  
  `;

const ClientSlide: FC<ClientProps> = ({ image, title, body, address, alternateText, apiImageUrl }) => {
  return (
    <ClientCard>
      <ClientDetail>
        <ImageWrap>
          {image ? <Image
            src={image}
            alt={alternateText}
            apiImageUrl={apiImageUrl}
          /> : <ProfileIcon
            src={UserIcon}>
          </ProfileIcon>}
        </ImageWrap>
        <Box ml={3} >
          <Title><Bold textColor={theme.colors.black}>{title}</Bold></Title>
          <H5 textColor={theme.colors.blue}>{address}</H5>
        </Box>

      </ClientDetail>

      <ClientContent>
        <H5 lineHeight={1.5} fontSize={theme.fontSize.base} textColor={theme.colors.mediumGray}>
          {truncate(body, 200)}</H5>
      </ClientContent>
    </ClientCard>
  );
};


ClientSlide.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  address: PropTypes.string,
};

export default ClientSlide;